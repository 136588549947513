import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Plaque from "../../primitives/Plaque"

import { EventTiles, EventsContainer } from "./Events.styles"

const formatDate = (date) => {
  const currDate = new Date(date)
  return currDate.toLocaleString()
}
const Events = () => {
  return (
    <EventsContainer id="Events">
      <h2>Events</h2>
      <p>Click on an event to get involved!</p>
      <StaticQuery
        query={graphql`
          query EventsQuery {
            allEventbriteEvent(filter: { status: { eq: "live" } }) {
              nodes {
                id
                name {
                  text
                }
                start {
                  local
                }
                url
              }
            }
          }
        `}
        render={data => (
          <EventTiles>
            {data.allEventbriteEvent.nodes.length ? data.allEventbriteEvent.nodes.map((node) => (
                <Plaque 
                  fontSize='22' 
                  text={node.name.text} 
                  key={node.id} 
                  secondaryText={formatDate(node.start.local)} 
                  href={node.url} 
                />
            )) : <p className="failed">Check back here for new events soon!</p>}
          </EventTiles>
        )}
      />
    </EventsContainer>
  )
}

export default Events
